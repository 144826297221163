import { useMutation, useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import { fetcher } from "lib/fetcher";
import { useEffect, useState } from "react";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import { getConnectedEntries } from "../../lib/prismaQueries/getConnectedEntries";
import AllBookingsCount from "./components/AllBookingsCount";
import { useSession } from "next-auth/react";
import { EntryStatus, EntryType } from "@prisma/client";
import SinglePost from "./components/SinglePost";
import queryClient from "lib/clients/react-query";
import { toast, ToastContainer } from "react-toastify";
import Button from "components/CustomButton";
import dayjs from "dayjs";
import { motion } from "framer-motion";

const container = {
  hidden: { opacity: 1, height: "auto" },
  visible: {
    opacity: 1,
    height: "auto",
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2,
    },
  },
};

const item = {
  hidden: { y: -10, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

type Props = {
  id: number;
  entries: NonNullable<
    Awaited<ReturnType<typeof getConnectedEntries>>
  >["entries"];
  capping: number;
  hideVariant?: boolean;
};

// get entries type from getConnectedEntries
type ConnectedEntryType = NonNullable<
  Awaited<ReturnType<typeof getConnectedEntries>>
>["entries"][number];

export const ConnectedPosts = ({
  id,
  entries,
  capping,
  hideVariant = false,
}: Props) => {
  const { t, lang } = useTranslation("post");
  const { data: session } = useSession();

  const router = useRouter();

  const [bookingLength, setBookingLength] = useState(0);
  const [dayIsOver, setDayIsOver] = useState(false);

  const notificationMessage = t("archivedNotification");
  const notify = () =>
    toast(notificationMessage, {
      icon: "📂",
    });

  const archiveMutation = useMutation({
    mutationFn: (id: number) =>
      fetcher(`/api/archiveConnection/${id}`, {
        method: "PUT",
      }),
    onSettled: () => {
      notify();

      queryClient.invalidateQueries({
        queryKey: [`entries`],
      });
    },
  });

  useEffect(() => {
    const bookingsLength =
      entries.reduce((acc, entry) => acc + entry.BookEntry.length, 0) || 0;
    setBookingLength(bookingsLength);
  }, [entries]);

  useEffect(() => {
    const dateOfEntry = dayjs(entries[0]?.bookDate).hour(0).minute(0); //normalize date to 00:00 to compare
    const dayDifference = dateOfEntry.diff(dayjs(), "day", true);
    if (dayDifference < 0) {
      setDayIsOver(true);
    } else {
      setDayIsOver(false);
    }
  }, [entries]);

  return (
    <article className="my-6 mb-12 flex justify-center">
      <div
        className={clsx(
          "relative block w-full rounded-[5px] bg-slate-200 p-6 dark:bg-slate-800"
        )}
      >
        <AllBookingsCount
          count={bookingLength}
          capping={capping}
          etype={capping ? "CAPPED" : "OPEN"}
        />
        {entries[0]?.bookDate ? (
          <h2 className="mb-1 inline w-full font-display text-lg font-medium leading-tight text-slate-700 md:mb-1 md:text-2xl dark:text-white">
            {new Intl.DateTimeFormat(router.locale, {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            }).format(new Date(entries[0]?.bookDate))}
          </h2>
        ) : null}
        {dayIsOver && session?.user?.admin ? (
          <span className="ml-3 inline-block">
            <Button
              click={() => archiveMutation.mutate(id)}
              additionalClassNames="bg-rose-100 dark:bg-rose-100 !text-black text-slate-700 "
            >
              {t("archiveButton")}
            </Button>
          </span>
        ) : null}
        <motion.div
          className="mt-8"
          variants={container}
          initial="hidden"
          animate="visible"
        >
          {entries.map(
            (entry) =>
              entry.status === EntryStatus.PUBLISHED && (
                <motion.div key={entry.id} variants={item}>
                  <SinglePost
                    entry={entry}
                    userId={session?.user?.id}
                    reducedPrice={session?.user?.reducedPrice}
                    connectedEntryId={id}
                    remainingBookings={capping - bookingLength}
                    etype={capping ? "CAPPED" : "OPEN"}
                    hideVariant={hideVariant}
                  />
                </motion.div>
              )
          )}
        </motion.div>
      </div>
    </article>
  );
};
