import React from "react";
import { useRouter } from "next/router";
import { signOut, useSession } from "next-auth/react";
import clsx from "clsx";
import getInitialsByName from "lib/getInitialsByName";

import { Button } from "../CustomButton";
import { HeaderLink } from "./components/HeaderLink";
import useTranslation from "next-translate/useTranslation";
import { LanguageSwitch } from "./components/LanguageSwitch";
import Link from "next/link";
import AdminMenu from "./components/AdminMenu";
import { ArrowRightOnRectangleIcon } from "@heroicons/react/24/outline";

export const Header = () => {
  const router = useRouter();
  const { t } = useTranslation("navigation");

  const isActive: (pathname: string) => boolean = (pathname) =>
    router.pathname === pathname;

  const [navOpen, toggleNavOpen] = React.useReducer((value) => !value, false);
  //const [value, setValue] = usePersistentContext("menu_state");
  const { data: session, status } = useSession();

  React.useEffect(() => {
    navOpen === true
      ? document &&
        document?.querySelector("body")?.classList.add("overflow-hidden")
      : document &&
        document?.querySelector("body")?.classList.remove("overflow-hidden");
  }, [navOpen]);

  let left = (
    <HeaderLink href="/" isActive={isActive("/")}>
      {t("All entries")}
    </HeaderLink>
  );

  let right = null;

  if (status === "loading") {
    left = (
      <HeaderLink href="/" isActive={isActive("/")}>
        {t("All entries")}
      </HeaderLink>
    );
    right = (
      <div>
        <p>Validating session ...</p>
      </div>
    );
  }

  if (!session) {
    right = (
      <div className="align-center flex flex-row justify-center space-x-4 text-lg font-semibold text-slate-500">
        <LanguageSwitch />
        <Button
          href="/api/auth/signin"
          isFakeButton
          data-active={isActive("/signup")}
        >
          {t("signIn")}
        </Button>
      </div>
    );
  }

  if (session) {
    left = (
      <>
        <HeaderLink href="/" isActive={isActive("/")}>
          {t("All entries")}
        </HeaderLink>
        {process.env.NEXT_PUBLIC_LOCATION === "Berlin" &&
        new Date() > new Date("2024-12-19") ? (
          <HeaderLink href="/events" isActive={isActive("/events")}>
            {t("Events")}
          </HeaderLink>
        ) : null}
        <HeaderLink
          href="/transfer-overview"
          isActive={isActive("/transfer-overview")}
        >
          {t("All Transfers")}
        </HeaderLink>

        {session?.user?.admin ? (
          <>
            <HeaderLink href="/drafts" isActive={isActive("/drafts")}>
              {t("Draft entries")}
            </HeaderLink>
            <HeaderLink href="/archive" isActive={isActive("/archive")}>
              {t("Archive")}
            </HeaderLink>
            <HeaderLink href="/bookings" isActive={isActive("/bookings")}>
              {t("All bookings")}
            </HeaderLink>
          </>
        ) : null}

        {session?.user?.email === "bookit@brandung.de" ? (
          <HeaderLink href="/archive" isActive={isActive("/archive")}>
            {t("Archive")}
          </HeaderLink>
        ) : null}

        {session?.user?.dev ? (
          <>
            <HeaderLink
              href="/communication"
              isActive={isActive("/communication")}
            >
              {t("sendMail")}
            </HeaderLink>
          </>
        ) : null}
        {session?.user?.admin ? (
          <>
            <HeaderLink
              href="/create"
              isActive={isActive("/create")}
              showOnlyOnMobile={true}
            >
              {t("New entry")}
            </HeaderLink>
          </>
        ) : null}
      </>
    );
    right = (
      <>
        {session?.user?.admin ? (
          <>
            <AdminMenu />
          </>
        ) : null}
        <Link
          href="/settings"
          className={clsx(
            "ml-3 mr-1 rounded-3xl bg-slate-200 px-2 py-1 text-sm font-medium text-slate-900 dark:bg-slate-600 dark:text-white",
            session?.user?.benefits &&
              "border-2 border-emerald-300 dark:border-emerald-600"
          )}
        >
          {session?.user?.name && getInitialsByName(session?.user?.name)}
        </Link>
        <LanguageSwitch />
        <button
          aria-describedby="logout-desc"
          onClick={() => signOut()}
          className="mx-3 w-6 text-indigo-600 "
        >
          <ArrowRightOnRectangleIcon className="h-8 w-8" />
        </button>

        <p id="logout-desc" className="hidden">
          Logout of the page
        </p>
      </>
    );
  }

  const burger = (
    <button
      className="group ml-3 flex h-10 w-10 cursor-pointer items-center justify-center md:hidden"
      aria-label="Toggle Navigation Menu"
      aria-expanded={navOpen ? true : false}
      onClick={() => {
        toggleNavOpen();
        //setValue(value === "open" ? "closed" : "open");
      }}
    >
      <div className="space-y-2">
        <span
          className={clsx(
            "block h-1 w-10 origin-center rounded-full bg-slate-500 transition-transform ease-in-out",
            !!navOpen && "translate-y-1.5 rotate-45"
          )}
        ></span>
        <span
          className={clsx(
            "block h-1 w-8 origin-center rounded-full bg-indigo-600 transition-transform ease-in-out  dark:bg-fuchsia-600",
            !!navOpen && "!w-10 -translate-y-1.5 -rotate-45"
          )}
        ></span>
      </div>
    </button>
  );

  return (
    <nav className="fixed top-0 z-50 w-full bg-white shadow-[0_-1px_0_0_rgb(234,234,234)_inset] dark:bg-slate-800 dark:shadow-[0_-1px_0_0_rgb(10,10,10)_inset]">
      <div className="mx-auto max-w-7xl sm:px-0 md:px-2 lg:px-8">
        <div className="relative flex h-14 items-center justify-between">
          <div className="flex md:h-full md:space-x-4">
            {burger}
            <ul
              className={clsx(
                "absolute top-[56px] z-10 ml-0 h-screen w-full flex-col bg-slate-100 transition-transform ease-in-out md:relative md:top-0 md:flex md:h-auto md:w-full md:flex-row md:justify-around md:bg-transparent dark:bg-slate-800",
                !navOpen && "hidden"
              )}
            >
              {left}
            </ul>
          </div>
          <div className=" inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            {right}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
